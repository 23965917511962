import React from "react"
import horse from "./horse.png"
import egg from "./egg.png"

function poem(x) {
    x = x.x
    switch (x) {
        case 0: {
            return (<div>
                <p style={{fontSize: "36px"}}>
                    a thousand horses who do taxes
                </p>
                <p>
                    by Vrinda Gandhi
                </p></div>)
        }
        case 1: {
            return (
                <p>if horses laid eggs, i would do taxes for my father<br /></p>
            )
        }
        case 2: {
            return (
                <p>if horses laid eggs, i would do taxes for my father<br />
                because i don't know taxes, even after i took business in school<br />
                </p>
            )
        }
        case 3: {
            return (<p>if horses laid eggs, i would do taxes for my father<br />
            because i don't know taxes, even after i took business in school<br />
            school teaches you shit about taxes</p>

            )
        }
        case 4: {
            return (<p>if horses laid eggs, i would do taxes for my father<br />
            because i don't know taxes, even after i took business in school<br />
            school teaches you shit about taxes<br/>
            they just tell you to do them- how?</p>
            )
        }
        case 5: {
            return (<p>if horses laid eggs, i would do taxes for my father<br />
            because i don't know taxes, even after i took business in school<br />
            school teaches you shit about taxes<br/>
            they just tell you to do them- how?<br/>
            why? for whom? for what? and when?</p>)
        }
        case 6: {
            return (<p>if horses laid eggs, i would do taxes for my father<br />
            because i don't know taxes, even after i took business in school<br />
            school teaches you shit about taxes<br/>
            they just tell you to do them- how?<br/>
            why? for whom? for what? and when?<br/>
            i am some- eighteen year old <b style={{color: "red"}}>error</b>
            </p>)
        } 
        case 7: {
            return (<p style={{color: "white"}}>if horses laid eggs, i would do taxes for my father<br />
            because i don't know taxes, even after i took business in school<br />
            school teaches you shit about taxes<br/>
            they just tell you to do them- how?<br/>
            why? for whom? for what? and when?<br/>
            i am some- eighteen year old <b style={{color: "red"}} className="VG04-error">error</b>
            </p>)
        }
        case 8: {
            return (<p></p>)
        } 
        case 9: {
            return (<p style={{color: "white"}}><img src={egg} className="VG04-egg"></img><br/>
            i think, i feel i wasn't supposed to be born in a class to do taxes and if i buy a thousand horses, how much tax will i pay?
            </p>)
        }
        case 10: {
            return (<p><img src={egg} className="VG04-egg"></img><br/>
            i think, i feel i wasn't supposed to be born in a class to do taxes and if i buy a thousand horses, how much tax will i pay?
            </p>)
        }
        case 11: {
            return (<p style={{color: "white"}}><img src={egg} className="VG04-egg"></img><br/>
            i think, i feel i wasn't supposed to be born in a class to do taxes and if i buy a thousand horses, how much tax will i pay?
            </p>)
        }
        case 12: {
            return (<p><img src={egg} className="chaos-egg-1"></img><br/>
            again, i am that bug in your computer that will leave your monitor hanging 
            </p>)
        }
        case 13: {
            return (<p><img src={egg} className="chaos-egg-2"></img><br/>
            that virus in your body that will multiply until i appear on your pretty face 
            </p>)
        }
        case 14: {
            return (<p><img src={egg} className="chaos-egg-3"></img><br/>
            i am that fire in your heart that can't be extinguished
            </p>)
        }
        case 15: {
            return (<p><img src={egg} className="chaos-egg-4"></img><br/>
            that crazy teenage YA romance novel about 700 pages and it's a series - 
            </p>)
        }
        case 16: {
            return (<b> BOOM           
            </b>)
        }
        case 17: {
            return (<b className="VG04-error"> BOOM           
                </b>)
        }
        case 18: {
            return (<p>
                i'm a bomb with eggs in it
                </p>)
        }
        case 19: {
            return (<p>           
            </p>)
        }
        case 20: {
            return (<p>like boys who throw stones on my window <br/> until it's broken into a thousand pieces
                
            </p>)
        }
        case 21: {
            return (<p>
            </p>)
        }
        case 22: {
            return (<p>  <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img><br/><br/>
                a thousand horses can do my taxes, right?
            </p>)
        }
        case 23: {
            return (<p>
                <p>   <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img><img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img><br/><br/>
                because i cannot, and i do not want my father to
            </p>
            </p>)
        }
        case 24: {
            return (<p>
                <p>  <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img><br/><br/>
                guess i'll just give my reports to an accountant
            </p>
            </p>)
        }
        case 25: {
            return (<p>
                <p>  <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img> <img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img><br/><br/>
                one with a foul smell, sweat glistened on forehead like a rabbit's white fur
            </p>
            </p>)
        }
        case 26: {
            return (<p></p>)
        }
        case 27: {
            return (<p>
"hey mr. accountant. please do my taxes"                
            </p>)
        }
        case 28: {
            return (<p>
"hey mr. accountant. please do my taxes"<br/> and he will do. because he doesn't believe in capitalism            
            </p>)
        }
        case 29: {
            return (<p>
"hey mr. accountant. please do my taxes"<br/> and he will do. because he doesn't believe in capitalism<br/>or so i think he doesn't                
            </p>)
        }
        case 30: {
            return (<p>
"hey mr. accountant. please do my taxes"<br/> and he will do. because he doesn't believe in capitalism<br/>or so i think he doesn't<br/>and he will laugh at my purchase of a thousand horses          
            </p>)
        }
        case 31: {
            return (<p>
"hey mr. accountant. please do my taxes"<br/> and he will do. because he doesn't believe in capitalism<br/>or so i think he doesn't<br/>and he will laugh at my purchase of a thousand horses<br/>"this ain't a trojan war", he would say                
            </p>)
        }
        case 32: {
            return (<p>
"hey mr. accountant. please do my taxes"<br/> and he will do. because he doesn't believe in capitalism<br/>or so i think he doesn't<br/>and he will laugh at my purchase of a thousand horses<br/>"this ain't a trojan war", he would say<br/>it isn't, but who will do my taxes after you, mr accountant?                
            </p>)
        }
        case 33: {
            return (<img style={{maxHeight: "60px"}} src={egg}></img>)
        }
        case 34: {
            return (<p><img style={{maxHeight: "40px"}} src={horse} className="VG04-egg"></img>Vrinda Gandhi is an eighteen-year-old literature enthusiast from Delhi, India.<br/> She loves street food, vanilla candles and wishes to travel and write stories about the obscure experiences of people.<br/>

            more info- <a style={{color: "blue"}} href="https://vrindag.carrd.co/">vrindag.carrd.co/</a></p>)
        }
        default: { return (<p></p>) }
    }

}

export default poem