import React from 'react'
import { Link } from 'react-router-dom'
import {Columns} from 'react-bulma-components'
import './style/PoemIndex.css'

function PoemIndex() {
  return (
    <div className="poem-App">
    <div className="poem-options poem-title-wrapper">
      <p className="poem-title">Poems</p>
    </div>
    <header className="poem-preview-box">
      <Link to="/poems/vg04">
        <div className="poem-preview">
          <p className="poem-preview-title">a thousand horses who do taxes</p>
          <p className="poem-preview-sub">by Vrinda Gandhi</p>
          <i className="poem-preview-sub">April 2022</i>
        </div>
        </Link>
             </header>
             <br/>
             <br/>
    <header className="poem-preview-box">
      <Link to="/poems/wl11">
        <div className="poem-preview" style={{backgroundColor: "peachpuff", color: "black"}}>
          <p className="poem-preview-title">there's this song</p>
          <p className="poem-preview-sub">by Will LaPorte</p>
          <i className="poem-preview-sub">Nov. 2021</i>
        </div>
        </Link>
             </header>
    <br/>
             <br/>
             <header className="poem-preview-box">
      <Link to="/poems/jm05">
        <div className="poem-preview">
          <p className="poem-preview-title">A Year or So</p>
          <p className="poem-preview-sub">by Jakob Maier</p>
          <i className="poem-preview-sub">July 2021</i>
        </div>
        </Link>
             </header>
             <br/>
             <br/>
             <header className="poem-preview-box">
      <Link to="/poems/mb03">
        <div className="poem-preview" style={{backgroundColor: "peachpuff", color: "black"}}>
          <p className="poem-preview-title">Letter for the Peach Mag Reading <br/> (We Killed John McCain!)</p>
          <p className="poem-preview-sub">by Matt Bosque</p>
          <i className="poem-preview-sub">Mar. 2021</i>
        </div>
        </Link>
             </header>
            
             <br/>
             <br/>
              <header className="poem-preview-box">
      <Link to="/poems/jn02">
        <div className="poem-preview">
          <p className="poem-preview-title">New Theories of the Everyday</p>
          <p className="poem-preview-sub">by Julianne Neely</p>
          <i className="poem-preview-sub">Jan. 2021</i>
        </div>
        </Link>
             </header>
  <div className="poem-options">
  <Columns>
  <Columns.Column>
  <Link to="/menu" className="mast-option abt-op1">Main Menu</Link>
  </Columns.Column>
  </Columns>
  </div>
  </div>
  );
}

  export default PoemIndex
  