import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day15() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(3, 2)
        setShowButton(isDay)
    }
    return (
        <div>
              <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-12">
                        <div>
                            <div className="jn02-box"><p><br/>15</p></div>
                        </div>
                        <div className="jn02-poem-box-content">
                            <p className="day2">
                            purple until you are red, red until you are blue<br/>
woman growing into nothing, I live through my hips and my hair<br/>
but looking in a mirror, I see nothing<br/>
challenging what has been passed down, blown up<br/>
keep waiting for the light to shriek, black out the sun<br/>
block its rays, the decaying of uranium<br/>
the smell of sun and battery acid, all in the name of inevitable violence<br/>
this side to be buried, in radioactive mud<br/>
I reciprocate, I reciprocate<br/>
if you were me then, who is <br/>
within our I’s, what else is left to a woman but<br/>
everyday life, the realm of the insignificant, <br/>
invisible yet indispensable, and then a soft stretching beam of light<br/>
this offering, this offering<br/>
is not good enough, is not good enough<br/>
for it to work, for it to work<br/>
what you are willing to give, what you are willing to give<br/>
must never grow back, must never grow back
                            </p>
                            <br/>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN0214" className="jn02-option">Day 14</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN02" className="jn02-option">Day 0</Link> 
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day15