import React from 'react'
import { Link } from 'react-router-dom'
import {Columns} from 'react-bulma-components'
import './style/Subs.css'
import logo from '../imgs/working.png';

function Subs() {

  return (
    <div className="sub-App">
    <div className="sub-options">
      <p className="sub-title">Submissions: <i style={{color: "green"}}>OPEN</i></p>
    </div>
    <header className="sub-desc-wrapper">
      <Columns>
      <Columns.Column>
      <p className="sub-desc">
        <ul>
          <li>BEFORE YOU SUBMIT, please read this all in full! Please send:</li>
          <br/>
          <li> - One of your poems that you believe works well with a medium like ours; not accepting previously published poems.
          </li>
          <li>
            - A brief pitch (three sentences max) on the digital aspects that'd be involved.
          </li>
          <li> - A third-person bio (three sentences max)
          </li><br/>
          <li>
            Send to prettycoolpoetrything@gmail.com with the phrase "heyo poem time" somewhere in the subject.
          </li>
        </ul>
        <br/>
        We will work with you on hashing out the details on the digital aspects, so feel free to shoot big on the pitch but remember we can't do a <i>wild</i> amount of programming.
      </p>
      </Columns.Column>
      </Columns>
    </header>
  <div className="sub-options">
  <Columns>
  <Columns.Column>
  <Link to="/menu" className="sub-option sub-op1">Main Menu</Link>
  </Columns.Column>
  </Columns>
  </div>
  </div>
  );
}

export default Subs