import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day7() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(2, 22)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <p className="jn02-number">VII</p>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <Columns>
                            <Columns.Column><p className="center">a woman walks into a room she sees a window she sees what she sees violence in a winter</p></Columns.Column>
                            </Columns>
                            <Columns class="is-mobile columns no-margin center-stuff">
                            <Columns.Column class="column is-4 day-7-box orange"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box red"><div className=""></div></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box pink"><div className=""></div></Columns.Column>
                            </Columns>
                            <Columns class="is-mobile columns no-margin">
                            <Columns.Column class="column is-4 day-7-box"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box light-blue"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box"></Columns.Column>
                            </Columns>
                            <Columns>
                            <Columns.Column><p className="center">evening raise your hand if you are exhausted to the point where snow looks like snow and</p></Columns.Column>
                            </Columns>
                            <Columns class="is-mobile columns no-margin">
                            <Columns.Column class="column is-4 day-7-box light-green"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box black"></Columns.Column>
                            </Columns>
                            <Columns>
                            <Columns.Column><p className="center">you don't even carry pepper spray on walks at night anymore who cares take my bones and</p></Columns.Column>
                            </Columns>
                            <Columns class="is-mobile columns no-margin">
                            <Columns.Column class="column is-4 day-7-box"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box light-gray"></Columns.Column>
                            <Columns.Column class="column is-4 day-7-box"></Columns.Column>
                            </Columns>
                            <Columns>
                            <Columns.Column><p className="center">watch me socially kapitally metaphorically no guts in as each moment lessens the body a little</p></Columns.Column>
                            </Columns>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN026" className="jn02-option">Day 6</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                    {showButton === true ? (
                        <Link to="/poems/JN028" className="jn02-option">Day 8</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day7