import React from "react";
import { Columns } from "react-bulma-components"
import './style/mastheadEntry.css'
import dan from "../imgs/portrait/dan.png"
import amanda from "../imgs/portrait/amanda.png"
import sarah from "../imgs/portrait/sarah.png"
import rose from "../imgs/portrait/rose.png"


function mastheadEntry(props){
    let active = props.props
    console.log(active)
if (active === "dan"){
        return(
    <div className="entry-wrapper">
    <Columns>
        <Columns.Column className="portrait">
        <img src={dan} className="portrait"/>
        </Columns.Column>
    
        <Columns.Column className="bio">
        <p className="name">Dan McKeon</p>
        <p className="title">Editor and Programmer</p>
        <p className="bio-text">Dan McKeon (they/them) is a poet, barista and web developer living in Philly. 
        They are the editor and programmer of Pretty Cool Poetry Thing and have appeared in Peach Mag, 
        Foundlings and Vagabond City. They enjoy coffee, socialism, and blaseball. Follow them for nonsense on <a href="https://twitter.com/cooldooski" target="_blank">Twitter.</a></p>
        </Columns.Column>
    </Columns>
    </div>)

    } else if (active === "amanda"){
        return(
        <div className="entry-wrapper">
        <Columns>
            <Columns.Column className="portrait">
            <img src={amanda} className="portrait"/>
            </Columns.Column>
        
            <Columns.Column className="bio">
            <p className="name">Amanda Silberling</p>
            <p className="title">Managing Editor</p>
            <p className="bio-text amanda">Amanda Silberling (she/her) is a poet/artist/journalist/Pokemon master based in Philadelphia. 
            You can find stuff she’s written at The Kenyon Review, The Adroit Journal, NPR, and other places. 
            She has a <a href="https://wowiftrue.com" target="_blank">podcast</a>, a <a href="https://goodpoemproject.com" target="_blank">second poetry thing</a>, a <a href="https://twitter.com/asilbwrites" target="_blank">Twitter</a> and other stuff at <a href="https://amandasilberling.com" target="_blank">amandasilberling.com</a>.</p>
            </Columns.Column>
        </Columns>
        </div>)

    } else if (active === "sarah"){
        return(
            <div className="entry-wrapper">
            <Columns>
                <Columns.Column className="portrait">
                <img src={sarah} className="portrait"/>
                </Columns.Column>
            
                <Columns.Column className="bio">
                <p className="name">Sarah Robbins</p>
                <p className="title">Assistant Editor</p>
                <p className="bio-text">Sarah Robbins (she/they) is a writer in Kansas City. They have work in Carte Blanche, Thin Air, Heavy Feather Review, and others. 
                They spend their free time trying to make their friends laugh. Follow them on <a href="https://twitter.com/saaraahkate" target="_blank">Twitter</a>.</p>
                </Columns.Column>
            </Columns>
            </div>)   

    } else if (active === "rose"){
        return(
            <div className="entry-wrapper">
            <Columns>
                <Columns.Column className="portrait">
                <img src={rose} className="portrait"/>
                </Columns.Column>
            
                <Columns.Column className="bio">
                <p className="name">Rose Guilfoyle</p>
                <p className="title">Illustrator</p>
                <p className="bio-text">Rose Guilfoyle is a medical research lab technician by day and an illustrator/cartoonist by night in Rochester, NY. 
                She owned a chameleon named Bart from 2017-2019, and that lizard loved poetry so much she had to start a zine with her pal Dan.</p>
                </Columns.Column>
            </Columns>
            </div>)    }
}

export default mastheadEntry