import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day5() {
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);

    function checkDay() {
        let isDay = timer.checkDate(2, 20)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <div className="jn02-box jn02-box-2 shadow-pop-tr"><p><br/>5</p></div>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <p>
                                what future for textiles?<br/>
                                what future for fragmentary imperatives?<br/>
                                what future for repetition?<br/>
                                what future for us?<br/>
                                what future for exploration?<br/>
                                what future for holsters?<br/>
                                what future for virus?<br/>
                                what future for poetry?<br/>
                                what future for poets?<br/>
                                what future for transcendental reflection?<br/>
                                what future for sex?<br/>
                                what future for empirical deduction?<br/>
                                what future for origins?<br/>
                                what future for cold?<br/>
                                what future for love?<br/>
                                what future for depravity?<br/>
                                what future?

                            </p>
                            <br />
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN024" className="jn02-option">Day 4</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        {showButton === true ? (
                            <Link to="/poems/JN026" className="jn02-option">Day 6</Link>) :
                            (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day5