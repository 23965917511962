import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day12() {
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);

    function checkDay() {
        let isDay = timer.checkDate(2, 27)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4"><p className="jn02-number">12</p >
                    </Columns.Column>
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <div className="jn02-box-2">
                            </div>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-4 center">
                        <div className="jn02-poem-box-content">
                            <br />
                        </div>
                    </Columns.Column>
                </Columns>
                <Columns class="columns">
                    <Columns.Column class="column is-3"><div className="jn02-box-2">
                    </div>
                    </Columns.Column>
                    <Columns.Column class="column is-6">
                        <div className="jn02-poem-box-content center">
                            <p>so I have come to spill blood<br/>
to hold a head in my hand<br/>
where in the DNA does the vision lie<br/>
you beckon to me<br/>
you welcome me in<br/>
gently I push<br/>
breathing that fills the walls<br/>
and the quiet that will follow<br/>
my perception of what is double<br/>
framing cracks of my light<br/>
if you were sitting in an audience<br/>
imagining me<br/>
is this what you’d see<br/>
prisms cast about the body<br/>
</p>
                            <br />
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-3"><div className="jn02-box-2">
                    </div>

                    </Columns.Column>
                </Columns>

            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN0211" className="jn02-option">Day 11</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        {showButton === true ? (
                            <Link to="/poems/JN0213" className="jn02-option">Day 13</Link>) :
                            (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day12