import React, { useState } from "react"
import Poem from "./components/poem"
import './MB03.css'

function MB03() {
    let [scene, setScene] = useState(0)
    let [out, setOut] = useState(false)
    let [act, setAct] = useState("white")

    function handleKeyPress() {
        addClose()
        setTimeout(nextScene, 1100)
    }
    function addClose() {
        setOut(true)
    }
    function nextScene(){
        let newScene = scene + 1
        setOut(false)
        setScene(newScene)
        switch (scene) {
            case 4: {
                changeAct("black")
            break;}
            case 14: {changeAct("blue")
            break;}
            case 18: {
                changeAct("white")
            }
        }
    }

    function changeAct(x){
        setAct(x)
    }

    return (
        <div className={`MB03-${act}`} onKeyPress={handleKeyPress} onClick={handleKeyPress}>
                <div className={`${out ? `MB03-${act}-out` : `MB03-${act}-in`}`}>
                    <Poem x={scene} />
                </div>
            </div>
    )
}

export default MB03