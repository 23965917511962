import React, { useState } from "react"
import Poem from "./components/poem"
import './VG04.css'

function VG04() {
    let [scene, setScene] = useState(0)
    let [out, setOut] = useState(false)
    let [act, setAct] = useState("white")

    function handleKeyPress() {
        addClose()
        setTimeout(nextScene, 200)
    }
    function addClose() {
        setOut(true)
    }
    function nextScene(){
        let newScene = scene + 1
        setOut(false)
        setScene(newScene)
        switch (scene) {
            case 7: {
                changeAct("black")
            break;}
            case 8: {changeAct("grey")
            break;}
            case 25: {
                changeAct("black")
            break;}
            case 26: {
                changeAct("white")
            break;}
            case 35: {
                setScene(0)
            }
        }
    }

    function changeAct(x){
        setAct(x)
    }

    return (
        <div className={`VG04-${act}`} onKeyPress={handleKeyPress} onClick={handleKeyPress}>
                <div className={`${out ? `VG04-${act}-out` : `VG04-${act}-in`}`}>
                    <Poem x={scene} />
                </div>
            </div>
    )
}

export default VG04