import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day1() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(2, 16)
        setShowButton(isDay)
    }

    return (
        <div>
            <div className="jn02-poem-box">
                <Columns>
                    <Columns.Column>
                        <div className="jn02-poem-box-square">
                            <div className="jn02-box"><p>1</p></div>
                        </div>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="jn02-poem-box-content ">
                            <Columns class="columns is-mobile">
                                <Columns.Column class="is-4 column">
                                    <p className="jn02-red">
                                        <br />
                            I WAKE <br />
                            I LOVE <br />
                            I POETRY <br />
                            I WATCH <br />
                            I RUN <br />
                            I EAT <br />
                            I HYDRATE <br />
                            I CRY <br />
                            I LOOK <br />
                            I LEAVE<br />
                                    </p>
                                </Columns.Column>
                                <Columns.Column class="is-8 column">
                                    <p>
                                        everyday life story will to carve
                                <br /> <br />
                                life extending from a wide range
                                <br /> <br />
                                puzzling of current social classes
                                <br /> <br />
                                ideas it can serve to reinforce
                                <br /> <br />
                                concept like everyday thinking
                                <br /> <br />
                                feminism a necessary precondition
                                <br /> <br />
                                important factor in nature
                                <br /> <br />
                                reference points in the background
                                <br /> <br />
                                points they glow
                                time is synonymous with
                            </p>
                                </Columns.Column>
                            </Columns>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN02" className="jn02-option">Day 0</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                    {showButton === true ? (
                        <Link to="/poems/JN022" className="jn02-option">Day 2</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day1