import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day4() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(2, 19)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <p className="jn02-number">4</p ><div className="jn02-box-2">
                                <p className="jn02-12px">hello how are you winter</p>
                                <p className="jn02-12px jn02-sideways-right">theory of a space to be<br/>defined</p>
                                <p className="jn02-12px jn02-sideways-left">malleable system goodbye &</p>
                                <p className="jn02-12px jn02-sideways-down">defined a defined space a</p>
                                </div>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <br/>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN023" className="jn02-option">Day 3</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                    {showButton === true ? (
                        <Link to="/poems/JN025" className="jn02-option">Day 5</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day4