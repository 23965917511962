import React, {useState, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02home() {

const [time, setTime] = useState(timer.countdownTimer())
const[showButton, setShowButton] = useState(false)

useEffect(() => {
    checkDay();
}, []);

function checkDay(){
    let isDay = timer.checkDate(2, 15)
    setShowButton(isDay)
}

function updateTime(){
    setTime(timer.countdownTimer())
}

useInterval(() => {
    updateTime();
}, 1000)



function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }



    return (
        <div className="jn02">
            <div className="jn02-poem-box">
                <Columns>
                    <Columns.Column class="column is-6">
                        <div className=" jn02-title">
                                <p>New Theories of the Everyday</p>
                                <p>by Julianne Neely</p>
                                <br/>
                                <br/>
                                <br/>
                                <p className="jn02-sub">
                                    Editor's Note: This piece is fully grown. It grew for 15 days, a new part appearing at midnight.
                                    <br/>
                                    <br/>
                                    This piece was optimized to be read on a computer using Chrome or Firefox. It is still readable on mobile and other browsers,
                                    but you may experience problems.
                                </p>
                                </div>
                    </Columns.Column>
                    <Columns.Column class=" column is-6">
                        <div className="jn02-title jn02-sub">
                           <p>Julianne Neely received her MFA degree from the Iowa Writer’s Workshop, where she received the Truman Capote Fellowship, the 2017 John Logan Poetry Prize, and a Schupes Fellowship for Poetry. She is currently a Poetics PhD candidate and an English Department Fellow at the University at Buffalo. Her writing has been published in Hyperallergic, VIDA, The Poetry Project, The Rumpus, The Iowa Review, and more. You can read more at <a href="http://julianneneely.net">julianneneely.net</a></p>
                        </div>
                        
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        {showButton === true ? (
                        <Link to="/poems/JN021" className="jn02-option">Day 1</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02home