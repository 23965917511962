import React from "react"

function poem(x) {
    x = x.x
    switch (x) {
        case 0: {
            return (<div>
                <p style={{fontSize: "36px"}}>
                    Letter for the Peach Mag Reading (We Killed John McCain!)
                </p>
                <p>
                    by Matt Bosque
                </p></div>)
        }
        case 1: {
            return (
                <p>Yo!<br />
            I don’t want to scare anyone right?<br />
            But I’m 99% sure we killed John McCain early<br />
            Like the 4 of us:<br />
            Me, you, Jasmine, and maybe Hannah, <br />
            And I know it seems like a coincidence<br />
            But there are no coincidences really</p>
            )
        }
        case 2: {
            return (
                <p>And the reader on stage says maybe<br />
                It was our collective energy <br />
                But I’m pretty tired so I don’t think it’s that<br />
                </p>
            )
        }
        case 3: {
            return (<p>And maybe it was poetry<br />
                But Dan just lost their voice <br />
                After biting into a peach <br />
                So probably not 	</p>

            )
        }
        case 4: {
            return (<p>But it’s weird though,<br />
                Dan’s voice was different the rest of the night<br />
                And we almost reached the singularity <br />
                At three in the morning
            </p>)
        }
        case 5: {
            return (<p>
                But nothing else was different.
            </p>)
        }
        case 6: {
            return (<p > And hey?<br />
                Do you think when we die <br />
                Our words go anywhere?<br />
            </p>)
        } 
        case 7: {
            return (<p > Do you think if we die before intended<br />
                That the words we had left to say<br />
                Stay on the earth and linger?<br />
            </p>)
        }
        case 8: {
            return (<p> Like physically?<br />
                And I don’t mean paper physically <br />
                But I mean more like a person<br/>
                A <span className="paper-person">paper person </span>
                If you will
            </p>)
        } 
        case 9: {
            return (<p> Is there ever enough left behind<br />
                For something like that?<br />
               (We could do it for sure, <br />
               I mean we have enough paper <br />
               Between my project<br />
               And your chapbook) but<br />
            </p>)
        }
        case 10: {
            return (<p> Can other people?
            </p>)
        }
        case 11: {
            return (<p> And look <br />
                Honest question: <br />
                Do you think Meg would <br />
                Have been friends with it?<br />
                The person I mean <br />
                The <span className="paper-person">paper one </span>
            </p>)
        }
        case 12: {
            return (<p> Because I’ve been thinking a lot <br />
                About the reading <br />
                And the singularity <br />
                Of course, you know the one <br />
                And it’s a joke but <br />
            </p>)
        }
        case 13: {
            return (<p> If it was real <br />
                This would be how we did it  <br />
                The killing I mean          <br />
            </p>)
        }
        case 14: {
            return (<p> Because the secret to <br />
                Something like this <br />
                Is that or other <br />
                But also the timing  <br />
            </p>)
        }
        case 15: {
            return (<p> For example: <br />
                We may be outside in the rain <br />
                But we are also here in my apartment <br />
                And 20 minutes from now jasmine is asleep <br />
                On the floor already <br />
                Which is not a paradox if you knew us
            </p>)
        }
        case 16: {
            return (<p> And if it is not paradox <br/>
                It’s a poem <br/>
                And as a poem <br/>
                All the highlights can live on <br/> 
                As if they have nothing to leave behind <br/>             
            </p>)
        }
        case 17: {
            return (<p> Because there are no coincidences really <br/>
                Are there?
            </p>)
        }
        case 18: {
            return (<p>
                And so, <br/>
If someone did kill John McCain  <br/>
It was surely us <br/>
With our moments <br/>
This one, or all of them <br/>
And what comes after is thoroughly our fault <br/>
            </p>)
        }
        case 19: {
            return (<p> I feel no guilt but, <br/>
                We will surely be arrested soon <br/>
                And one of us will roll over on the rest<br/>
                Which is all-good <br/>
                All friendly<br/>
                Kind of funny<br/>           
            </p>)
        }
        case 20: {
            return (<p> And if it’s Mike <br/>
                We have nothing <br/>
                To worry about <br/>
                
            </p>)
        }
        case 21: {
            return (<p>
                And if it’s Dan <br/>
And we go save them <br/>
Then it will be just another story <br/> 
To tell strangers 
            </p>)
        }
        case 22: {
            return (<p> Best believe <br/>
                I’d roll over so quickly <br/>
                Because it be funny! <br/>
                And we’d all laugh about it <br/> 
                
            </p>)
        }
        case 23: {
            return (<p>
                And If I did get arrested <br/>
I would leave behind this paper <br/>
For the <span className="paper-person">paper person</span> <br/>
Of course <br/>

            </p>)
        }

        default: { return (<p></p>) }
    }

}

export default poem