import React from 'react'
import { Link } from 'react-router-dom'
import {Columns} from 'react-bulma-components'
import './style/Menu.css'
import bart from "../imgs/bart-sprite.png"
import susie from "../imgs/susie-sprite.jpg"

function Menu() {
function Deltarune(){
  document.getElementById("deltarune-box").removeAttribute('hidden')
  document.getElementById("deltarune-text").removeAttribute('hidden')
}

    return (
      <div className="menu-App">
        <div className="menu-options">
      <Columns>
      <Columns.Column>
      <Link to="abt" className="menu-option menu-op1">About</Link>
      </Columns.Column>
      <Columns.Column>
      <Link to="mast" className="menu-option menu-op2">Masthead</Link>
      </Columns.Column>
      <Columns.Column>
      <Link to="subs" className="menu-option menu-op3">Submissions</Link>
      </Columns.Column>
      <Columns.Column>
      <Link to="acz/" target="_blank" rel="noopener noreferrer" className="menu-option menu-op4">ACZ</Link>
      </Columns.Column>
      
      </Columns>    
        </div>
        <div id="deltarune-text" style={{color: "purple", fontSize: "4vh"}} hidden><p>GOD FUCKING DAMN IT BART <br/> WHERE THE FUCK ARE WE???</p></div>
        <div id="deltarune-box" hidden>
          <img style={{height: "20vw"}} src={susie}/>
          <img style={{height: "20vw"}} src={bart}/>
        </div>
        <br/>
        <header className="logo-wrapper">
          <p className="menu-title">Pretty Cool Poetry <blank onClick={Deltarune}>Thing</blank></p>
        </header><div className="menu-options">
        <Link to="poems" className="menu-poems-button">Poems</Link>
      
</div>
      </div>
    );
  }

  export default Menu
  