import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day14() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(3, 1)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-5">
                        <div className="jn02-poem-box-square">
                            <p className="jn02-number">sonnet</p >
                            <p>strange I think <br/>
metaphor has no value<br/>
apart from its function<br/>
what is a mirror made out of<br/>
smoking and tobacco<br/>
diet and physical activity<br/>
sun and other types of radiation<br/>
viruses and other forms of infection<br/> 
my heart is offbeat <br/>
flames engulf my chest<br/>
I have shed it all<br/>
something about heartbreak about humans about the void<br/>
the only thing you have that nobody else has is yourself <br/><br/>

like a fucked up sonnet, twist, you can leave this behind
</p>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-3">
                        <div className="jn02-poem-box-content" style={{paddingTop: "200px"}}>
                            <br/><div className="jn02-box-2" >
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN0213" className="jn02-option">Day 13</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                    {showButton === true ? (
                        <Link to="/poems/JN0215" className="jn02-option">Day 15</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day14