import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day11() {
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);

    function checkDay() {
        let isDay = timer.checkDate(2, 26)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-6"><p className="jn02-poem-box-content" style={{ padding: "4px", color: "#ff85ff" }}>get out of my way for god's sake</p>
                        <div className="jn02-poem-box-content" style={{ borderLeft: "2px solid black", borderRight: "2px solid black", padding: "10px", textAlign: "justify" }}>

                            I mutated into this | women are sobbing | the walls like you agedly cave | slightly at the middle |
                            unable to control themselves | memoir dance | blood rubbed together | sworn swooning kinship |
                            you bear a resemblance to someone | of beautiful bones | immaculate from finger to brainstem |
plant your feet | cringe and hold yourself at arm’s length | I remember when I was this glossy | Limbs intertwined | transparent we shift from one side to the other | I have outdone myself with this poem | this gorgeous painting of me | it’s just me looking more me than ever | staring at myself within the frame | of all the people I know I know you I can understand | a monstrosity<br />
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN0210" className="jn02-option">Day 10</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        {showButton === true ? (
                            <Link to="/poems/JN0212" className="jn02-option">Day 12</Link>) :
                            (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day11