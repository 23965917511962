import React from 'react'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom"
import About from "./pages/About.js"
import Masthead from "./pages/Masthead.js"
import Subs from "./pages/Subs.js"
import PoemIndex from "./pages/PoemIndex.js"
import Message from "./pages/Message.js"
import Menu from "./pages/Menu.js"
import Bot from "./pages/Bot.js"
import JN02home from './poems/JN02-pages/JN02home.js'
import JN02day1 from "./poems/JN02-pages/JN02day1.js"
import JN02day2 from './poems/JN02-pages/JN02day2.js'
import JN02day3 from "./poems/JN02-pages/JN02day3.js"
import JN02day4 from "./poems/JN02-pages/JN02day4.js"
import JN02day5 from "./poems/JN02-pages/JN02day5.js"
import JN02day6 from './poems/JN02-pages/JN02day6.js'
import JN02day7 from "./poems/JN02-pages/JN02day7.js"
import JN02day8 from "./poems/JN02-pages/JN02day8.js"
import JN02day9 from "./poems/JN02-pages/JN02day9.js"
import JN02day10 from './poems/JN02-pages/JN02day10.js'
import JN02day11 from "./poems/JN02-pages/JN02day11.js"
import JN02day12 from "./poems/JN02-pages/JN02day12.js"
import JN02day13 from "./poems/JN02-pages/JN02day13.js"
import JN02day14 from './poems/JN02-pages/JN02day14.js'
import JN02day15 from "./poems/JN02-pages/JN02day15.js"
import MB03 from "./poems/MB03/MB03"
import JM05 from "./poems/JM05/JM05"
import WL11 from "./poems/WL11/WL11"
import WL11a from "./poems/WL11/WL11a"
import VG04 from "./poems/VG04/VG04"
import ED04 from "./poems/ED04/ED04"

function App() {

return (
  <Router>
      <Switch>
        <Route exact path="/" component={Menu} />
        <Route exact path="/menu" component={Menu}/>
        <Route exact path="/abt" component={About} />
        <Route exact path="/mast" component={Masthead} />
        <Route exact path="/subs" component={Subs} />
        <Route exact path="/poems" component={PoemIndex} />
        <Route exact path="/poems/jn02" component={JN02home}/>
        <Route exact path="/poems/jn021" component={JN02day1} />
        <Route exact path="/poems/jn022" component={JN02day2}/>
        <Route exact path="/poems/jn023" component={JN02day3}/>
        <Route exact path="/poems/jn024" component={JN02day4}/>
        <Route exact path="/poems/jn025" component={JN02day5}/>
        <Route exact path="/poems/jn026" component={JN02day6}/>
        <Route exact path="/poems/jn027" component={JN02day7}/>
        <Route exact path="/poems/jn028" component={JN02day8}/>
        <Route exact path="/poems/jn029" component={JN02day9}/>
        <Route exact path="/poems/jn0210" component={JN02day10}/>
        <Route exact path="/poems/jn0211" component={JN02day11}/>
        <Route exact path="/poems/jn0212" component={JN02day12}/>
        <Route exact path="/poems/jn0213" component={JN02day13}/>
        <Route exact path="/poems/jn0214" component={JN02day14}/>
        <Route exact path="/poems/jn0215" component={JN02day15}/>
        <Route exact path="/poems/mb03" component={MB03}/>
        <Route exact path="/poems/vg04" component={VG04}/>
        <Route exact path="/poems/jm05" component={JM05}/>
        <Route exact path="/poems/wl11" component={WL11}/>
        <Route exact path="/poems/wl11a" component={WL11a}/>
        <Route exact path="/poems/ed04" component={ED04}/>
        <Route exact path="/beep" component={Bot}/>
      </Switch>
  </Router>
);
}

export default App;
