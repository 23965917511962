import React, { useState } from "react"
import { Link } from 'react-router-dom'
import AudioSpectrum from "react-audio-spectrum"
import songA from "./songs/a.mp3"
import songB from "./songs/b.mp3"
import songC from "./songs/c.mp3"
import songD from "./songs/d.mp3"
import songE from "./songs/e.mp3"
import songF from "./songs/f.mp3"
import songG from "./songs/g.mp3"
import songZ from "./songs/z.mp3"
import bartVibe from "./bart-vibe.png"
import bartWoah from "./bart-woah.png"
import './WL11.css'

function WL11() {
    let [currentScene, setCurrentScene] = useState(0)  
    let [postScene, setPostScene] = useState(1)
    let [currentSongName, setCurrentSongName] = useState("")
    let [nowPlaying, setNowPlaying] = useState(songA)
    let [nowBart, setNowBart] = useState(bartVibe)
    const scene = [
        {
            words: "that doesn't seem to exist",
            song:  songA,
            songInfo: "13AM [froggy remix] by boqeh"
        },
        {
            words: "somewhere deep in the cursed traffic cone <br/> i used to swerve around <br/> to no avail <br/> somewhere <br/> under all these folds i got by asking and asking <br/> and never asked for",
            song: songD,
            songInfo: "Cool Curse by Tall Glass"
        },
        {
            words: "hidden under a skyscraper pile of <br/> hours and years under the <br/> numbness of too many good tunes <br/> under the rubble of memory",
            song: songG,
            songInfo: "Reviewscape by Will LaPorte"
        },
        {
            words: "when too many rocks are kicked down the road <br/> til the bruise on my foot is my only <br/> association with <br/> father john misty's tedious croon",
            song: songB,
            songInfo: "Boxes [Space Demo] by Overheard"
        },
        {
            words: "my thumb has a matching purple bump <br/> from running miles through <br/> tracks scrolling through minutes",
            song: songE,
            songInfo: "Lucid Dreams by we've all got pain"
        },
        {
            words: "forgetting how to enjoy the experience of <br/> a noise barrage <br/> shutting my eyes tight to journeys while <br/> only wanting the destination",
            song: songC,
            songInfo: "Saffron [Space Demo] by Salt"
        },
        {
            words: "the three minutes and thirty eight seconds I found the song it's <br/> rozwell kid's wendy's trash can it's pretty <br/> good let's pretend it was worth idling for fifteen minutes and skipping <br/> people's souls poured over tweeter poured over wax and your single <br/> handed responsibility to the end of the world",
            song: songF,
            songInfo: "Changing Changes by Will LaPorte"
        },
        {
            words: "2017 banner year for the exact <br/> same thing over and <br/> over again",
            song: songA,
            songInfo: "13AM [froggy remix] by boqeh"
        }
    ]



    let buzz = new Audio(songZ);

    function handleKeyPress() {
        if (currentScene === 8){    
            document.getElementById("wl11-info").style.display = "block"
            setTimeout(initPostScene, 500)
        } else {
        setNowBart(bartWoah)
        setTimeout(nextScene, 500)
        buzz.play()}
        console.log(currentScene)
    }



    let width = window.innerWidth
    let height = window.innerHeight/2

    const Song = ()=>(
        <div style={{position: "fixed", left: 0, bottom: -10, backgroundColor: "transparent"}}>
        <audio id="audio-element" src={nowPlaying} loop/>
        
         
        <AudioSpectrum
                id="audio-canvas"
                width={width}
                height={height}
                audioId={'audio-element'}
                capColor={'black'}
                capHeight={2}
                meterWidth={30}
                meterCount={600}
                meterColor={[
                  {stop: 0, color: '#C2EABD'},
                  {stop: 0.5, color: '#5DA9E9'},
                  {stop: 1, color: 'white'}
                ]}
                gap={0}/>
                </div>
    )

    function initPostScene() {
        console.log(postScene)
        setPostScene(postScene + 1)
        setNowPlaying(scene[postScene].song)
        setCurrentSongName(`now playing: ${scene[postScene].songInfo}`)
        document.getElementById("audio-element").play()
        document.getElementById("scrollbox").scrollTop = document.getElementById("scrollbox").scrollHeight
        if (postScene === 7){setPostScene(postScene - 6)}
    }

    function nextScene() {        
        setNowBart(bartVibe)
        setCurrentScene(currentScene + 1)
        setNowPlaying(scene[currentScene].song)
        setCurrentSongName(`now playing: ${scene[currentScene].songInfo}`)
        document.getElementById("audio-element").play()   
        document.getElementById("wl11-start").style.display = "none"
        document.getElementById("wl11-name").style.display = "none"
        document.getElementById(`wl11-stanza${currentScene}`).style.display = "block"
        document.getElementById("scrollbox").scrollTop = document.getElementById("scrollbox").scrollHeight
    }



    return (
        <div className="wl11-body" onKeyPress={handleKeyPress} onClick={handleKeyPress}>
            <div className="wl11-poem-wrapper" id="scrollbox">
                <p className="wl11-poem"><blank style={{fontSize: "24px"}}>there's this song</blank> <blank id="wl11-name"> by will laporte</blank></p>
                <br/>
                <p className="wl11-poem">
                <p id="wl11-start" style={{fontSize: "20px"}}>SOUND ON! This poem has some <i>sick music</i> and is best enjoyed with said <i>sick music</i>. Click anywhere to get the tunes groovin' and words movin'. Works best on Chrome.<br/> <br/> <b>IMPORTANT NOTE: iOS users are recommended to use the <Link to="/poems/wl11a" className="menu-option">Lite Version</Link>. If you are using iOS (iPhone / iPads) or Safari on Mac, the music will NOT work. Using Chrome on Mac should work, but it will not work on any browser on iOS.</b></p>

                <blank  className="wl11-stanza" id="wl11-stanza0" hidden> that doesn't seem to exist </blank>
                <blank  className="wl11-stanza" id="wl11-stanza1" hidden> somewhere deep in the cursed traffic cone <br/> i used to swerve around <br/> to no avail <br/> somewhere <br/> under all these folds i got by asking and asking <br/> and never asked for </blank>
                <br/>
                <blank  className="wl11-stanza" id="wl11-stanza2" hidden>hidden under a skyscraper pile of <br/> hours and years under the <br/> numbness of too many good tunes <br/> under the rubble of memory</blank>
                <blank  className="wl11-stanza" id="wl11-stanza3" hidden>when too many rocks are kicked down the road <br/> til the bruise on my foot is my only <br/> association with <br/> father john misty's tedious croon</blank>
                <br/>
                <blank className="wl11-stanza"  id="wl11-stanza4" hidden>my thumb has a matching purple bump <br/> from running miles through <br/> tracks scrolling through minutes</blank>
                <blank  className="wl11-stanza" id="wl11-stanza5" hidden>forgetting how to enjoy the experience of <br/> a noise barrage <br/> shutting my eyes tight to journeys while <br/> only wanting the destination</blank>
                <br/>
                <blank  className="wl11-stanza" id="wl11-stanza6" hidden>the three minutes and thirty eight seconds I found the song it's <br/> rozwell kid's wendy's trash can it's pretty <br/> good let's pretend it was worth idling for fifteen minutes and skipping <br/> people's souls poured over tweeter poured over wax and your single <br/> handed responsibility to the end of the world</blank>
                <blank className="wl11-stanza" id="wl11-stanza7" hidden>2017 banner year for the exact <br/> same thing over and <br/> over again <br/></blank>
                                <i className="wl11-stanza" id="wl11-info" hidden style={{fontColor: "#251818"}}><hr/>this poem was written by Will LaPorte; the songs are by <a href="https://boqeh.bandcamp.com/track/13-am">boqeh</a>, <a href="https://linktr.ee/overheard.music">Overheard</a>, Salt, Tall Glass and Will LaPorte; the coding's by dan; feel free to hang and thx for stopping by ~</i>
</p>
                <br/>
                <br/>
                <br/>
                
              </div>  
              <div style={{position: "fixed", bottom: "0px"}}>
              <div className="wl11-bart-wrapper">
                  <img className="wl11-bart" src={nowBart}/>
        <p className="wl11-songinfo" id="wl11-songinfo">{currentSongName}</p> 
        </div><Song/> </div>
        
        </div>
    )
}

export default WL11