import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day9() {
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);

    function checkDay() {
        let isDay = timer.checkDate(2, 24)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4">
                        <div className="">
                            <div className="jn02-box jn02-box-2"><p style={{ textAlign: "right" }}> n</p><p className="flicker-4">i</p></div>
                            <p style={{lineHeight: "1rem"}}>
                                ninenineninenineninenine<br />
                                ninenineninenineninenine<br />
 ninenineninenineninenine<br />
 ninenineninenineninenine<br />
 ninenineninenineninenine<br />
 ninenineninenineninenine<br />
 ninenineninenineninenine<br />
 ninenineninenineninenine<br />
 ninenineninenineninenine<br />

                            </p>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <p>
shuffle self-portrait as genetic changes ionizing it smells of sulfur a joint<br/>
<p className="jello-diagonal-2">clicks radiation what tried to kill you before may very well</p>
be what<br/>
kills you now molding your <br/>
body into clay tethered <br/>
to the contagious<br/>
a blur against the backdrop<br/>
when friction finally drags you to<br/>
turn around repeat<br/>
turn around repeat<br/>
skimming erotic<br/>
captivated yet longing sense<br/>
of measure more<br/>
words not able to be verbalized<br/>
beats of substance<br/>
left me sad and cold cathartic<br/>
left me sad and cold and without song<br/>
                            </p>
                            <br />
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN028" className="jn02-option">Day 8</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        {showButton === true ? (
                            <Link to="/poems/JN0210" className="jn02-option">Day 10</Link>) :
                            (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day9