import React, { useState } from "react"
import { Link } from 'react-router-dom'
import songA from "./songs/a.mp3"
import songB from "./songs/b.mp3"
import songC from "./songs/c.mp3"
import songD from "./songs/d.mp3"
import songE from "./songs/e.mp3"
import songF from "./songs/f.mp3"
import songG from "./songs/g.mp3"
import songZ from "./songs/z.mp3"
import bartVibe from "./bart-vibe.png"
import './WL11.css'

function WL11a() {
    let [currentScene, setCurrentScene] = useState(0)
    let [postScene, setPostScene] = useState(1)  
    let [currentSongName, setCurrentSongName] = useState("")
    let [nowPlaying, setNowPlaying] = useState(songA)
    const scene = [
        {
            words: "that doesn't seem to exist",
            song:  songA,
            songInfo: "13AM [froggy remix] by boqeh"
        },
        {
            words: "somewhere deep in the cursed traffic cone <br/> i used to swerve around <br/> to no avail <br/> somewhere <br/> under all these folds i got by asking and asking <br/> and never asked for",
            song: songD,
            songInfo: "Cool Curse by Tall Glass"
        },
        {
            words: "hidden under a skyscraper pile of <br/> hours and years under the <br/> numbness of too many good tunes <br/> under the rubble of memory",
            song: songG,
            songInfo: "Reviewscape by Will LaPorte"
        },
        {
            words: "when too many rocks are kicked down the road <br/> til the bruise on my foot is my only <br/> association with <br/> father john misty's tedious croon",
            song: songB,
            songInfo: "Boxes [Space Demo] by Overheard"
        },
        {
            words: "my thumb has a matching purple bump <br/> from running miles through <br/> tracks scrolling through minutes",
            song: songE,
            songInfo: "Lucid Dreams by we've all got pain"
        },
        {
            words: "forgetting how to enjoy the experience of <br/> a noise barrage <br/> shutting my eyes tight to journeys while <br/> only wanting the destination",
            song: songC,
            songInfo: "Saffron [Space Demo] by Salt"
        },
        {
            words: "the three minutes and thirty eight seconds I found the song it's <br/> rozwell kid's wendy's trash can it's pretty <br/> good let's pretend it was worth idling for fifteen minutes and skipping <br/> people's souls poured over tweeter poured over wax and your single <br/> handed responsibility to the end of the world",
            song: songF,
            songInfo: "Changing Changes by Will LaPorte"
        },
        {
            words: "2017 banner year for the exact <br/> same thing over and <br/> over again",
            song: songA,
            songInfo: "13AM [froggy remix] by boqeh"
        }
    ]



    let buzz = new Audio(songZ);

    function handleKeyPress() {
        if (currentScene === 8){   
            document.getElementById("wl11-info").style.display = "block"
            var sounds = document.getElementsByTagName('audio');
  for(let i=0; i<sounds.length; i++) sounds[i].pause();
            initPostScene() 
        } else { 
            var sounds = document.getElementsByTagName('audio');
  for(let i=0; i<sounds.length; i++) sounds[i].pause();
        nextScene()
        buzz.play()
    }
        console.log(currentScene)
    }


    function initPostScene() {
        if (postScene === 8){setPostScene(0)}
        setPostScene(postScene + 1)
        setNowPlaying(scene[postScene].song)
        setCurrentSongName(`now playing: ${scene[postScene].songInfo}`)
        var audioPlayer = document.getElementById(`song${postScene}`)
        audioPlayer.play()
        document.getElementById("scrollbox").scrollTop = document.getElementById("scrollbox").scrollHeight
        if (postScene === 7){setPostScene(postScene - 6)}
    }

function nextScene() {
    setCurrentScene(currentScene + 1)
    setNowPlaying(scene[currentScene].song)
    setCurrentSongName(`now playing: ${scene[currentScene].songInfo}`)
    var audioPlayer = document.getElementById(`song${currentScene}`)
    audioPlayer.play()
    document.getElementById("wl11-start").style.display = "none"
    document.getElementById("wl11-name").style.display = "none"
    document.getElementById(`wl11-stanza${currentScene}`).style.display = "block"
    document.getElementById("scrollbox").scrollTop = document.getElementById("scrollbox").scrollHeight
    console.log(audioPlayer.audioTracks)
}

const Song = ()=>(
<div style={{position: "fixed", bottom: "0px"}}>
<div className="wl11-bart-wrapper">
<img className="wl11-bart" src={bartVibe}/>
<p className="wl11-songinfo" id="wl11-songinfo">{currentSongName}</p>

</div>
   
    </div>
)

    return (
        <div className="wl11-body wl11a-body" onKeyPress={handleKeyPress} onClick={handleKeyPress}>
            <div className="wl11-poem-wrapper" id="scrollbox" style={{height: "60vh"}}>
                <p className="wl11-poem"><blank style={{fontSize: "24px"}}>there's this song</blank> <blank id="wl11-name"> by will laporte</blank></p>
                <br/>
                <p className="wl11-poem">
                <p id="wl11-start" style={{fontSize: "20px"}}>LITE VERSION. Designed for iOS. Due to limitations, some of the visual elements had to be removed for the iOS and Safari for Mac versions. For the full experience, open the  <Link to="/poems/wl11" className="menu-option">poem</Link> in Chrome on any non-iOS device. <br/> <br/> <b></b></p>
                <blank  className="wl11-stanza" id="wl11-stanza0" hidden> that doesn't seem to exist </blank>
                <blank  className="wl11-stanza" id="wl11-stanza1" hidden> somewhere deep in the cursed traffic cone <br/> i used to swerve around <br/> to no avail <br/> somewhere <br/> under all these folds i got by asking and asking <br/> and never asked for </blank>
                <br/>
                <blank  className="wl11-stanza" id="wl11-stanza2" hidden>hidden under a skyscraper pile of <br/> hours and years under the <br/> numbness of too many good tunes <br/> under the rubble of memory</blank>
                <blank  className="wl11-stanza" id="wl11-stanza3" hidden>when too many rocks are kicked down the road <br/> til the bruise on my foot is my only <br/> association with <br/> father john misty's tedious croon</blank>
                <br/>
                <blank className="wl11-stanza"  id="wl11-stanza4" hidden>my thumb has a matching purple bump <br/> from running miles through <br/> tracks scrolling through minutes</blank>
                <blank  className="wl11-stanza" id="wl11-stanza5" hidden>forgetting how to enjoy the experience of <br/> a noise barrage <br/> shutting my eyes tight to journeys while <br/> only wanting the destination</blank>
                <br/>
                <blank  className="wl11-stanza" id="wl11-stanza6" hidden>the three minutes and thirty eight seconds I found the song it's <br/> rozwell kid's wendy's trash can it's pretty <br/> good let's pretend it was worth idling for fifteen minutes and skipping <br/> people's souls poured over tweeter poured over wax and your single <br/> handed responsibility to the end of the world</blank>
                <blank className="wl11-stanza" id="wl11-stanza7" hidden>2017 banner year for the exact <br/> same thing over and <br/> over again</blank>
                <i className="wl11-stanza" id="wl11-info" hidden style={{fontColor: "#251818"}}><hr/>this poem was written by Will LaPorte; the songs are by <a href="https://boqeh.bandcamp.com/track/13-am">boqeh</a>, <a href="https://linktr.ee/overheard.music">Overheard</a>, Salt, Tall Glass and Will LaPorte; the coding's by dan; feel free to hang and thx for stopping by ~</i>
</p>
                <br/>
                <br/>
              </div>  
              <div style={{position: "fixed", bottom: "0px"}}>
              <div className="wl11-bart-wrapper">
                <audio id="song0" src={songA} loop/>
<audio id="song1" src={songD} loop/>
<audio id="song2" src={songG} loop/>
<audio id="song3" src={songB} loop/>
<audio id="song4" src={songE} loop/>
<audio id="song5" src={songC} loop/>
<audio id="song6" src={songF} loop/>
<audio id="song7" src={songA} loop/> <Song/>
        <p className="wl11-songinfo" id="wl11-songinfo">{currentSongName}</p> 
        </div></div>
        
        </div>
    )
}

export default WL11a