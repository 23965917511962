import React, { useState } from "react"
import './JM05.css'

function JM05() {
    let [keyHit, setKeyHit] = useState(0)
    let [currentDay, setCurrentDay] = useState(1)
    let [typingWords, setTypingWords] = useState('')
    const poemWords = [
        [],
        // day 1
        ["T", "o", "da", "y ", "I ", "fired ", "a gun ", "straight at ", "the moon. ", "I said ", "something ", "dumb about ", "poetry. ", "I ", "looked ", "at ", "my phone ", "for two ", "and ", "a ", "half ", "hours. ", "I ", "ate ", "a ", "perfect ", "bowl of chili. ", "Eli Lilly ", "rang the ", "stock ", "market ", "closing bell. ", "I ", "n", "a", "r", "r", "o", "w", "l", "y ", "missed ", "a ", "deer ", "on ", "the ", "inter", "state", ". ", "I worked ", "all ", "three ", "of ", "my jobs. ", "I paid ", "rent on a ", "Best Buy ", "credit card."],
        // day 2
        ["Today ", "I ", "got ", "laid ", "off ", "and ", "was ", "h", "a", "p", "p", "y ", "about ", "it. ", "I stole ", "supplies ", "from ", "a ", "local ", "univ", "ersity. ", "I ", "brought ", "up ", "the ", "news ", "many ", "times ", "in ", "conversation. ", "I drank ", "two ", "beers ", "and found ", "my new ", "favorite tree. ", "I ", "did ", "60 push", "-ups and ", "ate a ", "tuna melt. ", "I ", "dropped ", "a ", "lava ", "lamp ", "in ", "the ", "middle of ", "the street. ", "I ", "have ", "so many ", "friends ", "at the ", "click of ", "a mouse. ", "I said ", "good ", "night ", "to ", "the ", "screen."],
        // day 3 
        ["Today ", "I ", "adopted ", "a ", "cat ", "out ", "of ", "boredom. ", "I ", "worked ", "b", "o", "t", "h ", "jobs ", "remotely. ", "I ", "cooked ", "a ", "lasagna ", "of ", "astounding ", "proportions. ", "I ", "spent a ", "whole lot ", "of money. ", "I ", "climbed to ", "the top ", "of a ", "snowy ", "hill. ", "I ", "did ", "not ", "start ", "a ", "war ", "in ", "the ", "Middle ", "East. ", "I ", "watched ", "myself ", "smile ", "on ", "television. ", "I ", "had ", "a ", "dream ", "I ", "blew ", "up ", "the ", "White ", "House."],
        //day 4
        ["Today ", "I ", "touched ", "four ", "different ", "animals. ", "I ", "sent ", "2", "5", "5", " emails", " nobody ", "wanted ", "to ", "receive. ", "I ", "practiced ", "a ", "meditation ", "technique ", "developed ", "by ", "the ", "CIA. ", "I ", "left ", "my ", "house ", "for ", "the ", "first ", "time ", "in ", "weeks. ", "I ", "made", " enemies ", "with ", "the ", "church ", "down ", "the ", "road. ", "Over ", "2,000 dead ", "again. ", "I ", "pushed ", "my ", "health ", "insurance ", "up ", "a ", "hill. ", "I ", "watched ", "it ", "roll ", "back ", "down ", "again."],
        //day 5
        ["Today ", "I ", "sat ", "in ", "the ", "shade. ", "I ", "drafted ", "a ", "statement ", "of ", "cor", "por", "ate ", "res", "pon", "sib", "ili", "ty. ", "I ", "agreed ", "with ", "something ", "my ", "father ", "said ", "15 ", "years ", "ago. ", "I ", "felt ", "the ", "algorithm ", "take ", "over ", "me. ", "I ", "worked ", "both ", "my ", "jobs. ", "I ", "thought ", "the ", "phrase ", "“psychedelic ward.” ", "I ", "shouted ", "in ", "the ", "sun ", "for ", "a ", "while. ", "I ", "began ", "to ", "understand ", "how ", "a ", "supervillain ", "feels."],
        //day 6
        ["Today ", "I ", "woke ", "up ", "next ", "to ", "a ", "beautiful ", "woman. ", "I ", "drew ", "a ", "card ", "labeled ", "MEADOW. ", "I ", "missed ", "a ", "payment ", "on ", "a ", "personal ", "loan. ", "I ", "watched ", "too ", "many ", "cooking ", "videos. ", "I ", "drank ", "half ", "a ", "beer ", "and ", "clicked ", "three ", "targeted ", "ads. ", "I ", "snuck ", "through ", "a ", "room ", "full ", "of ", "lasers ", "undetected. ", "I ", "did ", "not ", "work. ", "Fewer ", "than ", "1,000 ", "people ", "died. ", "I ", "wrote ", "a ", "poem ", "about ", "garden ", "hoses. "],
        //day 7
        ["Today ", "I ", "played ", "guitar ", "in ", "my ", "underwear. ", "My ", "credit ", "score ", "changed ", "dramatically. ", "I ", "rode ", "a ", "horse ", "so ", "far ", "into ", "the ", "desert ", "we ", "vanished ", "into ", "the ", "horizon. ", "I ", "quit ", "my ", "game ", "without ", "saving. ", "I ", "cooked ", "a ", "gallon ", "of ", "soup ", "in ", "the ", "microwave. ", "I ", "did ", "not ", "work. ", "I ", "did ", "not ", "write. ", "I ", "retreated ", "deep ", "into ", "myself. ", "I ", "found ", "many ", "empty ", "cups ", "of ", "water."],
        //day 8
        ["Today ", "I ", "worked ", "both ", "my ", "jobs. ", "I ", "ordered ", "dumplings ", "and ", "took ", "out ", "the ", "trash. ", "I ", "brought ", "a ", "curse ", "onto ", "myself ", "and my ", "loved ones. ", "I felt ", "a", "stonished ", "at the ", "numbers ", "of ", "the ", "dead. ", "I ", "rode ", "my ", "bike ", "to the ", "highest ", "point ", "of the ", "city. ", "I ", "could ", "have ", "laughed ", "with ", "more feeling. ", "I ", "did ", "not ", "vote ", "for ", "that ", "awful ", "man. ", "Either ", "of ", "them. "],
        //day 9
        ["Today ", "I ", "considered ", "logging ", "off. ", "I ", "fixed ", "a ", "car ", "with ", "my ", "own ", "two ", "hands. ", "I ", "ate ", "one meal ", "and ", "drank ", "six beers. ", "I ", "paid ", "$20 ", "for ", "a ", "hint ", "at ", "my ", "future. ", "4,000 ", "people ", "died. ", "I ", "had sex ", "and ", "took a ", "hot shower. ", "I ", "kickflipped ", "over ", "the ", "gates ", "of ", "hell. ", "A ", "number ", "of ", "good ", "things ", "happened ", "to me. ", "A p", "olice ", "officer’s ", "head ", "was c", "rushed", " in a ", "doorway."],
        //day 10
        ["Today ", "I ", "left ", "one job ", "to ", "go ", "full-time ", "at the other. ", "I ", "sent ", "$500 ", "to ", "a ", "lawyer ", "on ", "Venmo. ", "I ", "fed ", "an ", "apple ", "to ", "a ", "baby ", "raccoon. ", "I ", "thought ", "about ", "buying ", "a ", "planner. ", "I ", "stood ", "in ", "the ", "middle ", "of ", "a ", "tunnel ", "with ", "light ", "at ", "both ", "ends. ", "I ", "failed ", "to ", "burn ", "an ", "American ", "flag. ", "A ", "year ", "since ", "I ", "stayed ", "inside. ", "I ", "look ", "so ", "good ", "in ", "green."],
        //day 11
        ["Today ", "I ", "looked ", "at ", "my ", "phone. ", "I ", "drove ", "around. ", "I ", "ate ", "some ", "food. ", "I ", "lost ", "my ", "job. ", "I ", "drove ", "around ", "looking ", "at ", "my ", "phone. ", "I ", "ate ", "some ", "food ", "driving ", "around. ", "I ", "lost ", "my ", "job ", "looking ", "at ", "my ", "phone. ", "I ", "drove ", "around ", "losing ", "my ", "job. ", "I ", "looked ", "at ", "my ", "food. ", "I ", "ate ", "my ", "job. ", "I ", "drove ", "my ", "phone ", "eating ", "my ", "job ", "losing ", "around. ", "I ", "drove. ", "I ", "ate. ", "I ", "looked. ", "I ", "lost. ", "Okay."],
        //day 12
        ["Today ", "I’ll ", "wake ", "to ", "a ", "blinding ", "light. ", "Afternoon ", "birthday, ", "how ", "lovely. ", "I’ll ", "eat ", "tacos ", "and ", "be ", "un", "employed. ", "Make ", "a ", "difference ", "in ", "my ", "community. ", "I’ll ", "kiss ", "my ", "loving ", "girl", "friend. ", "Finish ", "a ", "book ", "and ", "go ", "outside. ", "Clear ", "a ", "spot ", "on ", "a ", "blood-", "covered ", "floor. ", "Sit ", "down ", "there ", "any ", "time ", "I ", "l", "i", "k", "e", ".", " "],
    ]
    const timeStamps = ["", "1/10/2020", "3/15/2020", "3/20/2020", "4/10/2020", "6/6/2020", "7/10/2020", "9/4/2020", "11/3/2020", "1/6/2021", "3/15/2021", "5/31/2021", "6/4/2021"]

    function handleKeyPress() {
        const textBox = document.getElementById('text-box-jm')
        textBox.value = typingWords
        setKeyHit(keyHit + 1)
        if (currentDay === 13) {
            setTypingWords(". . .")
        }
        else if (poemWords[currentDay][keyHit] === undefined) {
            setTypingWords(typingWords.concat('', ''))
            document.getElementById("send-button-jm").hidden = false
        }
        else {
            setTypingWords(typingWords.concat('', poemWords[currentDay][keyHit]))
        }
        setTimeout(updateWords, 1)
    }
    function updateWords() {
        const textBox = document.getElementById('text-box-jm')
        textBox.value = typingWords
    }
    function onClick() {
        document.getElementById(`message-${currentDay}`).hidden = false
        setTypingWords("")
        setCurrentDay(currentDay + 1)
        setKeyHit(0)
        console.log(currentDay)
        document.getElementById('text-box-jm').value = ""
        document.getElementById('send-button-jm').hidden = true
        document.getElementById('scroll-box').scrollTop = document.getElementById('scroll-box').scrollHeight
    }



    return (
        <div className="background-jm">
            <div className="JM05-day" onKeyPress={handleKeyPress}>
                <span className="JM05-top"></span>
                <div className="contact-name-jm">
                    <p>Jakob Maier</p>
                </div>
                <div id="scroll-box" className="message-field-jm" >
                    <div id="message-1" hidden>
                        <p className="time-stamp" >1/10/2020</p>
                        <p className="indv-message-sent">Today I fired a gun straight at the moon. I said something dumb about poetry. I looked at my phone for two and a half hours. I ate a perfect bowl of chili. Eli Lilly rang the stock market closing bell. I narrowly missed a deer on the interstate. I worked all three of my jobs. I paid rent on a Best Buy credit card.</p>
                    </div>
                    <div id="message-2" hidden>
                        <p className="time-stamp">3/15/2020</p>
                        <p className="indv-message-sent" >Today I got laid off and was happy about it. I stole supplies from a local university. I brought up the news many times in conversation. I drank two beers and found my new favorite tree. I did 60 push-ups and ate a tuna melt. I dropped a lava lamp in the middle of the street. I have so many friends at the click of a mouse. I say good night to the screen.</p>
                    </div>
                    <div id="message-3" hidden>
                        <p className="time-stamp">3/20/2020</p>
                        <p className="indv-message-sent" >Today I adopted a cat out of boredom. I worked both jobs remotely. I cooked a lasagna of astounding proportions. I spent a whole lot of money. I climbed to the top of a snowy hill. I did not start a war in the Middle East. I watched myself smile on television. I had a dream I blew up the White House.</p>
                    </div>
                    <div id="message-4" hidden>
                        <p className="time-stamp">4/10/2020</p>
                        <p className="indv-message-sent" >Today I touched four different animals. I sent 255 emails nobody wanted to receive. I practiced a meditation technique developed by the CIA. I left my house for the first time in weeks. I made enemies with the church down the road. Over 2,000 dead again. I pushed my health insurance up a hill. I watched it roll back down again.</p>
                    </div>
                    <div id="message-5" hidden>
                        <p className="time-stamp">6/6/2020</p>
                        <p className="indv-message-sent">Today I sat in the shade. I drafted a statement of corporate responsibility. I agreed with something my father said 15 years ago. I felt the algorithm take over me. I worked both my jobs. I thought the phrase “psychedelic ward.” I shouted in the sun for a while. I began to understand how a supervillain feels.</p>
                    </div>
                    <div id="message-6" hidden>
                        <p className="time-stamp">7/10/2020</p>
                        <p className="indv-message-sent">Today I woke up next to a beautiful woman. I drew a card labeled MEADOW. I missed a payment on a personal loan. I watched too many cooking videos. I drank half a beer and clicked three targeted ads. I snuck through a room full of lasers undetected. I did not work. Fewer than 1,000 people died. I wrote a poem about garden hoses. </p>
                    </div>
                    <div id="message-7" hidden>
                    <p className="time-stamp">9/4/2020</p>
                                        <p className="indv-message-sent">Today I played guitar in my underwear. My credit score changed dramatically. I rode a horse so far into the desert we vanished into the horizon. I quit my game without saving. I cooked a gallon of soup in the microwave. I did not work. I did not write. I retreated deep into myself. I found many empty cups of water. </p>

                    </div>
                    <div id="message-8" hidden>
<p className="time-stamp">11/3/2020</p>
                    <p className="indv-message-sent" >Today I worked both my jobs. I ordered dumplings and took out the trash. I brought a curse onto myself and my loved ones. I felt astonished at the numbers of the dead. I rode my bike to the highest point of the city. I could have laughed with more feeling. I did not vote for that awful man. Either of them. </p>
                    </div>
                    <div id="message-9" hidden>
<p className="time-stamp">1/6/2021</p>
                    <p className="indv-message-sent" >Today I considered logging off. I fixed a car with my own two hands. I ate one meal and drank six beers. I paid $20 for a hint at my future. 4,000 people died. I had sex and took a hot shower. I kickflipped over the gates of hell. A number of good things happened to me. A police officer’s head was crushed in a doorway.</p>
                    </div>
                    <div id="message-10" hidden>
<p className="time-stamp">3/15/2021</p>
                    <p className="indv-message-sent">Today I left one job to go full-time at the other. I sent $500 to a lawyer on Venmo. I fed an apple to a baby raccoon. I thought about buying a planner. I stood in the middle of a tunnel with light at both ends. I failed to burn an American flag. A year since I stayed inside. I look so good in green.</p>
                    </div>
                    <div id="message-11" hidden>
<p className="time-stamp">5/31/2021</p>
                    <p className="indv-message-sent">Today I looked at my phone. I drove around. I ate some food. I lost my job. I drove around looking at my phone. I ate some food driving around. I lost my job looking at my phone. I drove around losing my job. I looked at my food. I ate my job. I drove my phone eating my job losing around. I drove. I ate. I looked. I lost. Okay.</p>
                    </div>
                    <div id="message-12" hidden><p className="time-stamp">6/4/2021</p>                    <p className="indv-message-sent">Today I’ll wake to a blinding light. Afternoon birthday, how lovely. I’ll eat tacos and be unemployed. Make a difference in my community. I’ll kiss my loving girlfriend. Finish a book and go outside. Clear a spot on a blood-covered floor. Sit down there any time I like. </p>
</div>
                </div>
                <div className="message-box-jm">
                    <form>
                        <textarea id="text-box-jm" type="text" name="message" rows="2" autoFocus></textarea>
                        <button id="send-button-jm" type="button" onClick={() => onClick()} hidden>SEND</button>
                    </form>
                </div>
                <span className="JM05-bottom" />
            </div>
        </div>
    )
}

export default JM05