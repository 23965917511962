import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import {Columns} from 'react-bulma-components'
import { DanButton, AmandaButton, SarahButton, RoseButton } from '../components/buttons'
import MastheadEntry from '../components/mastheadEntry'
import './style/Masthead.css'

function Masthead() {
  const [active, setActive] = useState("dan")
  function DanActive(){
    let active = "dan"
    setActive(active)
  }
  function AmandaActive(){
    let active = "amanda"
    setActive(active)
  }
  function SarahActive(){
    let active = "sarah"
    setActive(active)
  }
  function RoseActive(){
    let active = "rose"
    setActive(active)
  }

  return (
    <div className="mast-App">
    <div className="mast-options mast-title-wrapper">
      <p className="mast-title">Masthead</p>
    </div>
    <header className="mast-logo-wrapper entry">
      <MastheadEntry props={active}/>
      </header>
  <div className="mast-options">
  <Columns>
  <Columns.Column className="entry">
  <blank onClick={DanActive}>
<DanButton props={active} />
</blank>
<blank onClick={AmandaActive}>
<AmandaButton props={active} />
</blank>
<blank onClick={SarahActive}>
<SarahButton props={active}/>
</blank>
<blank onClick={RoseActive}>
 <RoseButton props={active}/>
 </blank>
 
  </Columns.Column>
  </Columns>
  <Columns>
  <Columns.Column>
  <Link to="/menu" className="mast-option abt-op1">Main Menu</Link>
  </Columns.Column>
  </Columns>
  </div>
  </div>
  );
}

export default Masthead
  