import React, { useState } from "react"
import Poem from "./components/poem"
import './ED04.css'

function ED04() {
    let [scene, setScene] = useState(0)
    let [anamoly, setAnamoly] = useState("1.0")

    fetch("https://global-warming.org/api/temperature-api").then((response)=>response.json()).then((json) => setAnamoly(json.result[json.result.length-1].station)).then(console.log(anamoly))
   
    function handleKeyPress() {
        changeScene(anamoly)
    }


    function changeScene(anamoly){
        if (anamoly < 1){
            setScene(6)
        } else if (anamoly >= 1 & anamoly < 1.1){
            setScene(5)
        } else if (anamoly >= 1.1 & anamoly < 1.2) {
            setScene(4)
        } else if (anamoly >= 1.2 & anamoly < 1.3){
            setScene(3)
        } else if (anamoly >= 1.3 & anamoly < 1.4) {
            setScene(2)
        }else if (anamoly >= 1.4 & anamoly < 1.5){
            setScene(1)
        }         
    }

    return (
        <div className={`ED04-${scene}`} onKeyPress={handleKeyPress} onClick={handleKeyPress}>
                <div className='ED04'>
                    <Poem x={scene} z={anamoly}/>
                </div>
            </div>
    )
}

export default ED04