import React from "react"

function poem(x) {
    let y = x.x
    let z = x.z
    console.log(x)
    switch (y) {
        case 0: {
            return (<div><p style={{fontSize: "30px"}}><b>
                    Poem by Emma Davis</b>
                </p>
                <br/>
                Click or tap to read this world's poem.
                <br/>
                <br/>
                <p>
                    This poem changes to reflect the world you are reading it in. Throughout the year, different stanzas will appear depending on the severity of the previous month's 
                    average temperature compared to that month's average from 1951 to 1980. This data is taken from NASA's GISS Surface Temperature Analysis. As the world grows hotter,
                    some stanzas will become less and less common; some may all together go extinct. 
                </p>
                <br/>
                <p>Last month's average was <b className="flicker-text">{z}</b> degrees Celsius above the 1951-1980 average.</p>
                <br/>
                <p>
                <i>Emma Davis is a student with a tremendous love for her dogs and tremendous anxiety for the health of her long-suffering houseplants. She's always used poetry as a way to record the way things feel inside her brain, and is just starting to let some of it free for others to see. </i>    
                </p>
                <br/>
                <p style={{fontSize: "10px"}}>
                Citation: GISTEMP Team, 2023: GISS Surface Temperature Analysis (GISTEMP), version 4. NASA Goddard Institute for Space Studies. Dataset first accessed 2023-07-01 at <a href="https://data.giss.nasa.gov/gistemp/">data.giss.nasa.gov/gistemp</a>.</p></div>)
        }
        case 1: {
            return (
                <p>
            Wine red sunset<br />
            Ashes on the garden leaves</p>
            )
        }
        case 2: {
            return (
                <p>We stay behind our doors<br />
                It doesn't feel strange anymore<br />
                Seeing the sky through grease-streaked glass<br />
                And the sun an angry bruise<br/>
                On clouds that bleed out west
                </p>
            )
        }
        case 3: {
            return (<p>Sprinklers scream Defiance<br />
                Into the Martian atmosphere.<br />
                </p>
            )
        }
        case 4: {
            return (<p>We stay behind our doors<br />
                We've grown used to this.<br />
                Strange, to think<br />
                This is the fall.<br/>
                And leaves turn fiery orange<br/>
                And woodsmoke flavors winds<br/>
                And bark peels back with wailing cry<br/>
                To float, far, grey and thin
            </p>)
        }
        case 5: {
            return (<p>
                This time of harvest reaps us bare<br />
                Stalks of stiff thin-stubbled grass<br />
                In clay-cold hard-turned earth<br />
                The calluses of our toil<br />
                Prove no protection here<br />
            </p>)
        }
        case 6: {
            return (<p > Our blood still flows, does not coagulate<br />
                These wounds were here before<br />
                Perhaps we were born with them<br />
                We may certainly die with them<br/>
                For now the wine is still spilt on the floor
            </p>)
        } 
        default: { return (<p></p>) }
    }

}

export default poem