import React from "react";
import './style/buttons.css'
import dan from "../imgs/buttons/dan.png"
import danSelected from "../imgs/buttons/dan-selected.png"
import amanda from "../imgs/buttons/amanda.png"
import amandaSelected from "../imgs/buttons/amanda-selected.png"
import sarah from "../imgs/buttons/sarah.png"
import sarahSelected from "../imgs/buttons/sarah-selected.png"
import rose from "../imgs/buttons/rose.png"
import roseSelected from "../imgs/buttons/rose-selected.png"

function DanButton(props){
    let active = props.props
    console.log(active)
if (active === "dan"){
        return(
    <img src={danSelected} className="selected-button" />)
    } else return(<img src={dan} className="def-button"/>)
}

function AmandaButton(props){
    let active = props.props
    if (active === "amanda"){
        return(
    <img src={amandaSelected} className="selected-button"/>)
    } else return(<img src={amanda} className="def-button"/>)
}

function SarahButton(props){
    let active = props.props
    if (active === "sarah"){
        return(
    <img src={sarahSelected} className="selected-button"/>)
    } else return(<img src={sarah} className="def-button"/>)
}

function RoseButton(props){
    let active = props.props
    if (active === "rose"){
        return(
    <img src={roseSelected} className="selected-button"/>)
    } else return(<img src={rose} className="def-button"/>)
}

export { DanButton, AmandaButton, SarahButton, RoseButton}