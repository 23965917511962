import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day10() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(2, 25)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
            <Columns class="columns" style={{color: "#ff85ff"}}>
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <div className="jn02-box jn02-box-2 ten-box"><p>TEN</p></div>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <p>
                            I fear I am lost in translation<br/>
as though a ghost resides<br/>
quantitively searching for a way to move beyond the skeletal<br/>
women cross themselves heaving<br/>
relying on each other to create perfection<br/>
arms outstretched <br/>
feel a break coming on<br/>
it’s not good<br/>
the shoving<br/>
my lying form<br/>
tell me what you’re after<br/>
the self in Freudian territory <br/>
I can’t feel my life in my throat anymore<br/>

                            </p>
                            <br />
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN029" className="jn02-option">Day 9</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                    {showButton === true ? (
                        <Link to="/poems/JN0211" className="jn02-option">Day 11</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day10