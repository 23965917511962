import React from 'react'
import logo from '../logo.png';
import './style/Message.css'

function Message() {

    const message = []
                            
  const timer = ms => new Promise(res => setTimeout(res, ms))
  
  
  async function readMessage () {
    for (var x = 0; x < message.length; x++){
      sayMessage(message[x])
      await timer(8500)
    }
  }
  
  function sayMessage(x){
    document.getElementById("messages").innerHTML = x
  }
  setTimeout(readMessage, 3000)
  
  
    return (
      <div className="App">
          <div className="buffer"></div>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <br/>
        </header>
        <header className="App-header">
          <br/>
          <br/>
          <br/>
          <p id="messages"> INCOMING MESSAGE: </p>
        </header>
        <div className="buffer"></div>
      </div>
    );
  }

  export default Message
  