import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day6() {
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);

    function checkDay() {
        let isDay = timer.checkDate(2, 21)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <div className="jn02-box jn02-box-2"><p>six</p></div>
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <p>
                                a means without end<br/>
                                the art of creative thinking<br/>
                                <p className="vibrate-1">static noise</p>
                                visual registers<br/>
                                <br/>
                                the problem with seers<br/>
                                <br/>
                                they are<br/>
                                a lot<br/>
                                like poets
                            </p>
                            <br />
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN025" className="jn02-option">Day 5</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        {showButton === true ? (
                            <Link to="/poems/JN027" className="jn02-option">Day 7</Link>) :
                            (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day6