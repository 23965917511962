import React from 'react';
import { Link } from 'react-router-dom'
import {Columns} from 'react-bulma-components'
import './style/About.css'

function About() {

  return (
    <div className="abt-App">
    <div className="abt-options">
      <p className="abt-title">About</p>
    </div>
    <header className="abt-desc-wrapper">
      <Columns>
      <Columns.Column>
      <p className="abt-desc abt-op2">Pretty Cool Poetry Thing is a lit mag, in a way, sort of. We publish a poem every couple months using the advantages and capabilities of the digital format. 
      Some poems will grow daily, some will be interactive, some will have their own little world. Our goal is to give a fresh, sorta odd, sorta intriguing way to read poetry. 
      </p>
      <br></br>
      <p className="abt-desc abt-op3">If you'd like to keep up to date with everything, follow us on <a className="TP" href="https://twitter.com/poetrything" target="_blank">Twitter</a>. We pay our poets $30 for accepted work,
      which is only made possible through our <a className="TP" href="https://patreon.com/poetrything" target="_blank">Patreon</a>, so consider throwing a few bucks our way to help out. :)</p>
      </Columns.Column>
      </Columns>
    </header>
  <div className="abt-options">
  <Columns>
  <Columns.Column>
  <Link to="/menu" className="abt-option abt-op1">Main Menu</Link>
  </Columns.Column>
  </Columns>
  </div>
  </div>
);
}

export default About
  