import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import './style/JN02.css'
import timer from '../../utils/timer'

function JN02day13() {
    const[showButton, setShowButton] = useState(false)

    useEffect(() => {
        checkDay();
    }, []);
    
    function checkDay(){
        let isDay = timer.checkDate(2, 28)
        setShowButton(isDay)
    }
    return (
        <div>
            <div className="jn02-poem-box">
                <Columns class="columns">
                    <Columns.Column class="column is-4">
                        <div className="jn02-poem-box-square">
                            <p className="jn02-number">13</p >
                        </div>
                    </Columns.Column>
                    <Columns.Column class="column is-8">
                        <div className="jn02-poem-box-content">
                            <p style={{fontSize: "48px"}}>bitebite<br/>bitebite<br/>bitebite<br/>bitebite<br/>bitebite<br/>bitebite<br/>bite</p>
                            <br/><div className="jn02-box-2">
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="jn02-options">
                <Columns class="columns is-mobile">
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/poems/JN0212" className="jn02-option">Day 12</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                        <Link to="/menu" className="jn02-option">Home</Link>
                    </Columns.Column>
                    <Columns.Column class="column is-4 jn02-col">
                    {showButton === true ? (
                        <Link to="/poems/JN0214" className="jn02-option">Day 14</Link> ) : 
                        (<div><p>Not Yet</p></div>)}
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    );
}

export default JN02day13