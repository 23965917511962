var { DateTime, Interval, Duration } = require('luxon');

const day1 = DateTime.fromObject({ day: 15, zone: 'America/New_York' })
const day2 = DateTime.fromObject({ day: 17, zone: 'America/New_York' })
const day3 = DateTime.fromObject({ day: 18, zone: 'America/New_York' })
const day4 = DateTime.fromObject({ day: 19, zone: 'America/New_York' })
const day5 = DateTime.fromObject({ day: 20, zone: 'America/New_York' })
const day6 = DateTime.fromObject({ day: 21, zone: 'America/New_York' })
const day7 = DateTime.fromObject({ day: 22, zone: 'America/New_York' })
const day8 = DateTime.fromObject({ day: 23, zone: 'America/New_York' })
const day9 = DateTime.fromObject({ day: 24, zone: 'America/New_York' })
const day10 = DateTime.fromObject({ day: 25, zone: 'America/New_York' })
const day11 = DateTime.fromObject({ day: 26, zone: 'America/New_York' })
const day12 = DateTime.fromObject({ day: 27, zone: 'America/New_York' })
const day13 = DateTime.fromObject({ day: 28, zone: 'America/New_York' })
const day14 = DateTime.fromObject({ month: 3, day: 1, zone: 'America/New_York' })
const day15 = DateTime.fromObject({ month: 3, day: 2, zone: 'America/New_York' })
const day16 = DateTime.fromObject({ month: 3, day: 3, zone: 'America/New_York' })

function checkDate(m, d) {
    let now = DateTime.now({ zone: 'America/New_York' })
    let day = DateTime.fromObject({ month: m, day: d, zone: 'America/New_York' })
    let res = Interval.fromDateTimes(now, day16).isAfter(day)
    return true
}

function countdownTimer() {
    let now = DateTime.now().setZone('est')
    let then = now.plus({days:1}).set({hour: 0, minute: 0, second: 0})
    let countdown = Interval.fromDateTimes(now, then).toDuration(['hours', 'minutes', 'seconds']).toObject()
    let res = Duration.fromObject(countdown).toISOTime({suppressMilliseconds:true})
    return res
}

module.exports = {
    checkDate,
    countdownTimer
}