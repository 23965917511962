import React, {useState} from "react"
import { Columns } from "react-bulma-components"
import "./style/About.css"
import "./style/Bot.css"

function Bot(){
    let [text, setText] = useState([""])
    let [dialogue, setDialogue] = useState("Hello?")
    let [round, setRound] = useState(0)
    let [eyes, setEyes] = useState(":")
    let str = ["Hello, I am Beep.", ". . . Beep !", "I'm a super cool poetry bot . . . the bee's pajamas, dude.", "Think you're splitting hairs, bud. Anyway, I need more time to get my insides aligned. You know how it is. Talk soon :)", ". . . :)"]
    let responses = ["Hello?", "Whoa. Who are you?", "But like . . . what are you?", "I think you mean bee's knees. Or cat's pajamas?", "OK?" ]
    function makeWords() {
        setEyes(": )")
        setText("")
        setDialogue("")    
        console.log(str)
        str = str[round]
        let words = str.split(" ")
        console.log(words)
        speak(words)
    }
    
    function speak(words) {
        console.log(words)
        let i = 0
        let x = 0
        let z = words.length
        let textLocal = ""
        go(i, z)
        function go() {
            let word = words[x]
            if (word === ".") {
                setText(textLocal + ". ")
                textLocal = textLocal + ". "
            } else {
                setText(textLocal + word)
                textLocal = textLocal + word + " "
            }
            i++;
            x++;
            console.log(text)
            if (i < z) {
                setTimeout(go, 200);
            } else {
                setRound(round + 1)
                setDialogue(responses[round + 1])
            }
        }
    }

    return(
        <div className="abt-App">
    <div className="abt-options">
      <b className="beep-eyes vibrate-2">{eyes}</b>
    </div>
    <header className="abt-desc-wrapper">
        <p className="bot-text">{text}</p>
    </header>
  <div className="abt-options">
  <Columns>
  <Columns.Column>
  <p className="abt-op1 abt-option dia-text" onClick={makeWords}>{dialogue}</p>
  </Columns.Column>
  </Columns>
  </div>
  </div>
    )
}

export default Bot